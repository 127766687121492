<template>

<div class="container">
    <div class="row">
        <div class="col-12 mt-2">
            <h3>เขียนบล็อก</h3>
            <hr>
        </div>
        <div class="col-12">
            <div class="form-group mb-5" style="width:200px;height:200px">
                <label>ภาพปก</label>
                <input type="file" id="upload_logo">
                <input type="hidden" class="form-control" name="logo">
            </div>
        </div>
        <div class="col-12">
            <div class="form-group">
                <label>ชื่อเรื่อง</label>
                <input type="text" class="form-control" placeholder="ใส่ชื่อเรื่อง" v-model="data.topic">
            </div>
        </div>
        <div class="col-12">
            <div class="form-group">
                <label>หมวดหมู่บล็อก</label>
                <select class="form-control" v-model="data.category">
                    <option value="news" selected>ข่าว</option>
                    <option value="manual">คู่มือการใช้งาน</option>
                </select>
            </div>
        </div>
       
        <div class="col-12">
             <div class="form-group">
                <label>เนื้อหา</label>
                <div id="toolbar">
                    <button class="ql-bold">Bold</button>
                    <button class="ql-italic">Italic</button>
                </div>
                <div id="editor" style="height:400px">
                    <p>Hello World!</p>
                </div>
            </div>
        </div>
        <div class="col-12">
            <div class="form-group">
                <label>แท็ก</label>
                <input type="text" class="form-control" id="tags">
            </div>
        </div>
        <div class="col-12">
            <div class="form-group">
                <button class="btn btn-block btn-primary" @click="submit">บันทึก</button>
            </div>
        </div>
        
    </div>
</div>
</template>
<script>
/* eslint-disable */
import firebase from "firebase/app"
import Quill from "quill"
import dayjs from 'dayjs'
import 'dayjs/locale/th'
import $ from 'jquery'
import "firebase/auth";
import "firebase/database";
import "quill/dist/quill.snow.css";
import Tagify from '@yaireo/tagify'
import '@yaireo/tagify/dist/tagify.css'
import Swal from 'sweetalert2'



import * as FilePond from 'filepond';
import FilePondPluginImageCrop from 'filepond-plugin-image-crop';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginImageTransform from 'filepond-plugin-image-transform';
import FilePondPluginImageEdit from 'filepond-plugin-image-edit';
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import 'filepond-plugin-image-edit/dist/filepond-plugin-image-edit.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import 'filepond/dist/filepond.min.css';
FilePond.registerPlugin(FilePondPluginImageCrop, FilePondPluginImagePreview, FilePondPluginImageEdit, FilePondPluginImageTransform, FilePondPluginFileEncode)


export default 
{
    data () {
        return {
            quill : null,
            data : {
                logo : null,
                topic : null,
                tags : null,
                category : null,
            }
        }
    },
    mounted() 
    {
        let vue = this

        vue.loadFilePond("upload_logo", "logo", "1:1")

        this.quill = new Quill('#editor', {
            modules: { toolbar: '#toolbar' },
            theme: 'snow'
        });

        var input = document.querySelector('#tags'),
        tagify = new Tagify(input)

        input.addEventListener('change', onChange)
        function onChange(e){
            let json = JSON.parse(e.target.value)
            let tags = []
            $.each(json,function(id,val){
                tags.push(val.value)
            })
            vue.data.tags = tags
        }
    },
    methods :
    {
        async submit()
        {
            let l = this.data
            if(!l.topic || !l.tags || !l.category || !l.logo) this.g_errorMsg("กรอกข้อมูลให้ครบถ้วน")
            else if(this.quill.getText().length <= 10) this.g_errorMsg("ใส่เนื้อหาน้อยเกินไป")
            else if(l.topic.length <= 10) this.g_errorMsg("ชื่อเรื่องน้อยเกินไป")
            else if(l.tags.length <= 0) this.g_errorMsg("กรุณาใส่ Tag")
            else
            {
                l.timestamp = firebase.database.ServerValue.TIMESTAMP
                l.content = this.quill.getContents() // เพิ่ม quill เข้าตัวแปร data
                firebase.database().ref(`blog/${l.category}`).push(l, (error) => {
                    if (error) 
                        this.g_errorMsg(error.message)
                    else 
                        Swal.fire('Successful', `เขียนบล็อกสำเร็จ`, 'success').then(f => {
                            window.location.reload()
                        })
                        

                    }
                )
            }
        },
        loadFilePond(id, vuemodel, AspectRatio, url = false) {
            let vue = this
            const inputElement = document.querySelector(`input[id="${id}"]`)
            let files = []
            if (url) {
                files = [{
                    source: url,
                    options: {
                        type: 'local'
                    }
                }]
                vue.imageUpload[vuemodel] = url
            }
            const pond = FilePond.create(inputElement, {
                files,
                allowFileEncode: true,
                labelIdle: `<span class="filepond--label-action">อัพโหลดรูปภาพ</span>`,
                imageCropAspectRatio: AspectRatio,
                stylePanelLayout: 'compact integrated',
                stylePanelAspectRatio: AspectRatio === "3:1" ? "2:1" : AspectRatio,
                styleLoadIndicatorPosition: 'center bottom',
                styleButtonRemoveItemPosition: 'center bottom',
                server: {
                    load: (uniqueFileId, load,error) => {
                        fetch(uniqueFileId)
                            .then(res => res.blob())
                            .then(load)
                            .catch(error)
                    },
                    onload: (response) => {
                        console.log(response);
                        return response;
                    },
                    process: (fieldName, file, metadata, load, error, progress, abort, transfer, options) => {
                        const formData = new FormData();
                        formData.append("name", file.name)
                        formData.append("title", file.name)
                        formData.append("description", "upload from djstreaming")
                        formData.append("image", file)
                        const request = new XMLHttpRequest();
                        request.open('POST', 'https://imgur-apiv3.p.rapidapi.com/3/image');
                        // request.open('POST', 'https://api.imgur.com/3/image.json');
                        request.setRequestHeader('Authorization', 'Client-ID 1493042c14d79e9')
                        request.setRequestHeader('x-rapidapi-key', 'eec51770fbmsh9422396f8082876p15a47ejsncbb78043c7b1')
                        request.upload.onprogress = (e) => {
                            progress(e.lengthComputable, e.loaded, e.total)
                        }
                        request.onload = function () {
                            if (request.status >= 200 && request.status < 300) {
                                let json = JSON.parse(request.responseText)
                                vue.data[vuemodel] = json.data.link
                                load(request.responseText);
                            } else
                                error('oh no');
                        }
                        request.send(formData);
                        return {
                            abort: () => {
                                request.abort();
                                abort();
                            }
                        };
                    }
                }
            })
        },
    },
}

</script>